import {
    IntlEnum,
    Typography,
    IntlMoney
  } from "@common/components";
  import { Column } from "react-table";
  import { ReimbursementValue } from "@we/orders/contracts/ReimbursementValue";
  import { GridviewData } from "@shared/components/GridViewData";
  
  interface GridViewProps<T> {
    data: T[],
  }
  
  const columns: Column<ReimbursementValue>[] = [
    {
      Header: <IntlEnum name="we_orders_grid" value="reimbursement_value" />,
      accessor: "value",
      Cell: ({ value }) => <IntlMoney value={value} />,
    },
    {
      Header: <IntlEnum name="we_orders_grid" value="formula" />,
      accessor: "formula",
    },
  ];
  
  export default function GridReimbursementValues<ReimbursementValue>({
    data,
  }: GridViewProps<ReimbursementValue>) {
    return (
      <>
        <Typography variant="h6" gutterBottom component="div">
          <IntlEnum name="menu_monitoro" value="reimbursement"/>
        </Typography>
  
        <GridviewData<ReimbursementValue>
          columns={columns}
          data={data}
        />
      </>
    )
  }
  