import { Column } from "react-table";
import { useCallback, } from "react";
import { IntlEnum, ApiLoadingSpinner, IntlDateTime } from "@common/components";
import { getValueEnumObject } from "@shared/helpers";
import { GridviewData } from "@shared/components";
import { useOrderDetails } from "@we/orderDetails/hooks/useOrderDetails";
import { Order } from "@we/orders/contracts/Order";
import GridProducts from "@we/orderDetails/components/GridProducts/GridProducts";
import GridInvoices from "@we/orderDetails/components/GridInvoices/GridInvoices";
import GridReimbursementValues from "@we/orderDetails/components/GridReimbursementValues/GridReimbursementValues";
import { EnumStatusOrder } from "@we/orders/enums/EnumStatusOrder";
import { StatusOrder } from "@we/orders/types/StatusOrder";
import { useJwtUser } from '@common/hooks';

interface ListOrderDetailsProps {
  order_code: string;
  industry_code: string;
}

export default function ListOrderDetails({ order_code, industry_code }: ListOrderDetailsProps) {
  const order =  useOrderDetails({ order_code, industry_code });
  const { user } = useJwtUser();
  const columns: Column<Order>[] = [
    {
      Header: <IntlEnum name="we_orders_grid" value="order_code" />,
      accessor: "order_code",
    },
    {
      Header: <IntlEnum name="we_orders_grid" value="customer_code" />,
      accessor: "customer_code",
    },
    {
      Header: <IntlEnum name="we_orders_grid" value="wholesaler_branch_code" />,
      id: 'wholesaler_branch_code',
      accessor: "wholesaler_branch",
      Cell: ({ value }) => value?.code ?? null,
    },
    {
      Header: <IntlEnum name="we_orders_grid" value="tradetools_created_at" />,
      accessor: "tradetools_created_at",
      Cell: ({ value }) => <IntlDateTime value={value} />,
    },
    {
      Header: <IntlEnum name="we_orders_grid" value="status" />,
      accessor: "status",
      Cell: ({ value }) => getValueEnumObject<StatusOrder>(EnumStatusOrder, value),
    },
  ];

  
  if (user?.hasSsoRole('master')) {
    columns.splice(2, 0, {
      id: 'wholesaler_branch_name',
      accessor: 'wholesaler_branch',
      Header: <IntlEnum name="we_orders_grid" value="wholesaler" />,
      Cell: ({ value }) => value?.name ?? null,
    })
    columns.join()
  }

  const renderRowSubComponentReimbursementValues = useCallback(
    (row) => (
      <GridReimbursementValues data={row.original.reimbursement_values} />
    ),
    []
  );

  const renderRowSubComponent = useCallback(
    (row) => (
      <>
        <GridProducts data={row.original.products} />
        <br />
        <GridInvoices
          data={row.original.invoices}
          renderRowSubComponent={row.original.invoices.length > 0 ? renderRowSubComponentReimbursementValues : undefined} 
        />
      </>
    ),
    []
  );
  
  const RenderGridViewData = () => {
    return (
      <GridviewData<Order>
        columns={columns}
        data={order.data?.edges}
        renderRowSubComponent={renderRowSubComponent}
      />
    );
  }

  if (order.isLoading) {
    return (
      <div className="w-56 ml-2">
        <ApiLoadingSpinner />
      </div>
    );
  }

  return (
    <RenderGridViewData />
  )
}
