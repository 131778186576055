import {
  IntlEnum,
  Typography,
  IntlPercentage
} from "@common/components";
import { Column } from "react-table";
import { Product } from "@we/orders/contracts/Product";
import { GridviewData } from "@shared/components";

interface GridViewProps<T> {
  data: T[],
}

const columns: Column<Product>[] = [
  {
    Header: <IntlEnum name="we_orders_grid" value="ean" />,
    accessor: "ean",
  },
  {
    Header: <IntlEnum name="we_orders_grid" value="discount" />,
    accessor: "discount_percentage",
    Cell: ({ value }) => <IntlPercentage value={value}/>,
  },
];

export default function GridProducts<Product>({
  data
}: GridViewProps<Product>) {
  return (
    <>
      <Typography variant="h6" gutterBottom component="div">
        <IntlEnum name="orders" value="products"/>
      </Typography>

      <GridviewData<Product>
        columns={columns}
        data={data}
      />
    </>
  )
}
