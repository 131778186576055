import {
  IntlEnum,
  Typography,
} from "@common/components";
import { Column } from "react-table";
import { Invoice } from "@we/orders/contracts/Invoice";
import { GridviewData } from "@shared/components";

interface GridViewProps<T> {
  data: T[],
  renderRowSubComponent?: Function
}

const columns: Column<Invoice>[] = [
  {
    Header: <IntlEnum name="we_orders_grid" value="invoice_id" />,
    accessor: "id",
  },
];

export default function GridInvoices<Invoice>({
  data,
  renderRowSubComponent
}: GridViewProps<Invoice>) {
  return (
    <>
      <Typography variant="h6" gutterBottom component="div">
        <IntlEnum name="we_orders_grid" value="invoice"/>
      </Typography>

      <GridviewData<Invoice>
        columns={columns}
        data={data}
        renderRowSubComponent={renderRowSubComponent}
      />
    </>
  )
}
