import { useQuery } from "react-query";
import { convertResponseCursorPaginated } from "@shared/helpers";
import { Order } from "@we/orders/contracts/Order";
import { useStoresGateway } from "@common/hooks/useStoresGateway";
import { gql } from "graphql-request";

interface UseOrderDetailsProps {
  order_code: string;
  industry_code: string;
}

export function useOrderDetails(options: UseOrderDetailsProps) {  
  const { request } = useStoresGateway();

  return useQuery("order:" + JSON.stringify(options), async () => {
    const { order } = await request(
      gql`
        query order(
          $order_code: Int!
          $industry_code: String!
        ) {
          order (
            order_code: $order_code
            industry_code: $industry_code
          ) {
            id
            order_code
            tradetools_created_at
            customer_code
            products {
              ean
              discount_percentage
            }
            status
            invoices {
              id
              reimbursement_values {
                value
                formula
              }
            }
            industry_code
            wholesaler_branch {
              name
              code
            }
          }
        }
      `,
      options
    );

    const data: any = {
      data: [order]
    }

    return convertResponseCursorPaginated<Order>(data);
  }, {
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}
